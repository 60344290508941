import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div>
        <img
          src="images/lib.png" // Replace with your image path
          alt="Under Construction"
          className="mb-6 h-auto max-w-full"
        />
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Website Under Construction
        </h1>
        <p className="text-gray-600 mb-8 animate-bounce">
          We're working hard to bring you an amazing experience. Stay tuned!
        </p>
        <a
          href="/files/lib.pdf" // Replace with your PDF file path
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline hover:text-blue-700"
        >
          Click to view our Profile
        </a>
      </div>
    </div>
  );
}

export default App;
